  nav, nav button{
  align-items: center;

}

.bnb{
border: solid 2px  #383f68;
box-shadow: 0 0 5px #383f68;
}
.swiper {
  width: 600px;
  height: 300px;
}
/* .tuyuu{
  position: sticky;
  top: 12%;
  height: calc(100vh - 90vh);
  left: 0;
  z-index: 1;
} */

nav{
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: solid 2px white;
}
.jdgdjd{
  border-bottom: solid 2px #383f68;
}
.jdgdjd button{
 border-bottom: solid 2px #383f68;
}

.main2{

  grid-template-columns: repeat(5, 19%);
  gap: 1rem;
  margin-top: 2%;
}
@media (min-width: 390px) {
  .swiper {
    /* display:none; */
    padding: 0 2px;
    width: 370px;
  height: 170px;
  
}
}
@media (min-width: 768px) {

.swiper {
    width: 1000px;
  height: 400px;

}

}
